* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  scroll-behavior: smooth;
  scroll-padding: 100px;
}
body {
  min-height: 100vh;
}
:root {
  --primary-color: #fff;
  --secondary-color: #27348b;
  --tertiary-color: #d6e4ef;
  --quarternary-color: #d6af62;
  --grey-color: #6f7181;
  --light-blue: #2085d5;
}
::placeholder {
  color: rgba(211, 211, 211, 1);
}
::-webkit-scrollbar {
  display: none;
}
@font-face {
  font-family: "GilroyBlack";
  src: local("GilroyBlack"),
    url("./assets/fonts/Gilroy-Black.ttf") format("truetype");
  font-weight: 900;
  font-display: swap;
}
@font-face {
  font-family: "GilroyBold";
  src: local("GilroyBold"),
    url("./assets/fonts/Gilroy-Bold.ttf") format("truetype");
  font-weight: bold;
  font-display: swap;

}
@font-face {
  font-family: "GilroyExtraBold";
  src: local("GilroyExtraBold"),
    url("./assets/fonts/Gilroy-ExtraBold.ttf") format("truetype");
  font-weight: bolder;
  font-display: swap;

}
@font-face {
  font-family: "Gilroy-100";
  src: local("GilroyUltraLight"),
    url("./assets/fonts/Gilroy-UltraLight.ttf") format("truetype");
  font-weight: 100;
  font-display: swap;

}
@font-face {
  font-family: "Gilroy-200";
  src: local("GilroyThin"),
    url("./assets/fonts/Gilroy-Thin.ttf") format("truetype");
  font-weight: 300;
  font-display: swap;

}
@font-face {
  font-family: "Gilroy-500";
  src: local("GilroySemiBold"),
    url("./assets/fonts/Gilroy-SemiBold.ttf") format("truetype");
  font-weight: 500;
  font-display: swap;

}
body {
  font-family: "Gilroy-100";
}

a {
  text-decoration: none;
}
